<template>

  <div class="row" ref="plasticTypeForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="name"
                      rules="required"
                      name="The Name"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="name"
                                name="name"
                                fou
                                v-model="formData.name">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <ValidationProvider
                  vid="content"
                  rules=""
                  name="The Content"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="content"
                           :error="failed ? errors[0]: null"
                           label="Content"
                           name="content"
                           fou
                           v-model="formData.content">
                  </fg-text>
                </ValidationProvider>

              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right">
                    <div slot="content">(345 x 225)</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.image"
                  >
                  </prime-uploader>
                </div>
                <div class="form-group">
                  <label>Video</label>
                  <ValidationProvider
                    vid="video_type"
                    rules=""
                    name="The Video Type"
                    v-slot="{ passed, failed,errors }">
                    <fg-select
                      name="video_type"
                      size="large"
                      filterable
                      clearable
                      placeholder="Video Type"
                      :error="failed ? errors[0]: null"
                      :input-classes="'select-default'"
                      :label="'Video Type'"
                      :list="VideoTypes"
                      :listItemLabel="'label'"
                      :listItemValue="'value'"
                      v-model="formData.video_type">
                    </fg-select>
                  </ValidationProvider>
                  <div class="form-group" v-if="formData.video_type === 'UPLOADED'">
                    <label>Uploaded Video</label>
                    <el-tooltip placement="right" v-if="false">
                      <div slot="content"></div>
                      <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                    </el-tooltip>
                    <prime-video-uploader
                      :preview-width="'200px'"
                      :preview-height="'200px'"
                      :elementNum="1"
                      v-model="formData.video"
                    >
                    </prime-video-uploader>
                  </div>
                  <div class="form-group" v-if="formData.video_type == 'EMBEDDED'">
                    <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                      <div slot="content">{{ getBannerVideoInfo() }}</div>
                      <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                    </el-tooltip>
                    <ValidationProvider
                      vid="embedded_video"
                      rules=""
                      name="The Embedded Video"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Embedded Video"
                                name="embedded_video"
                                fou
                                v-model="formData.embedded_video">
                      </fg-input>
                    </ValidationProvider>
                    <div v-html="formData.embedded_video">
                    </div>
                  </div>
                </div>
                <label>Examples</label>
                <div class="col-12">
                  <template class="row" v-for="(example,exampleIndex) in formData.examples">
                    <fg-input type="text"
                              :key="exampleIndex"
                              :label="'Example '+(exampleIndex+1)"
                              v-model="formData.examples[exampleIndex]">
                    </fg-input>
                    <div class="col-sm-1 align-self-center mt-3">
                      <l-button
                        @click="removeExample(formData.examples, exampleIndex)"
                        type="danger" size="sm">
                        <i class="fa fa-trash"></i>
                      </l-button>
                    </div>
                  </template>
                  <l-button
                    class="mt-3"
                    type="success"
                    @click="addNewExample(formData.examples)"
                    size="sm">
                    <i class="fa fa-plus"></i>
                  </l-button>
                </div>
                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/plastic-types/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        name: "",
        content: "",
        image: "",
        video: "",
        video_type: '',
        embedded_video: "",
        examples: [],
        is_active: true,
      },

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.plasticTypeForm
    });


    this.axios.post("plastic-types/builder").then((response) => {
      this.VideoTypes = response.data.videoTypes;

      this.id = this.$route.params['id'];
      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Plastic Type";
        this.getPlasticType();
      } else {
        this.editMode = false;
        this.formTitle = "Add Plastic Type";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    });

  },


  methods: {
    getPlasticType() {
      let data = {
        'id': this.id,
      };
      this.axios.post("plastic-types/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Plastic Type Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let formData = {...this.formData};
      if (formData.video_type === "UPLOADED") {
        formData.embedded_video = '';
      } else if (formData.video_type === "EMBEDDED") {
        formData.video = '';
      } else {
        formData.embedded_video = '';
        formData.video = '';
      }
      if (this.editMode === true) {
        request = this.axios.put("plastic-types/update/" + this.id, formData);
        successMessage = "Plastic Type Updated Successfully";
      } else {
        request = this.axios.post("plastic-types/create", this.formData);
        successMessage = "Plastic Type Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/plastic-types/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    getBannerImageInfo() {
      if (this.mediaInfo && this.mediaInfo["plasticType_image"]) {
        return this.mediaInfo["plasticType_image"];
      } else {
        return "";
      }
    },
    getBannerVideoInfo() {
      if (this.mediaInfo && this.mediaInfo["plasticType_video"]) {
        return this.mediaInfo["plasticType_video"];
      } else {
        return "";
      }
    },
    addNewExample(examples) {
      examples.push('');
    },
    removeExample(examples, index) {
      examples.splice(index, 1);
    },
  }
}
</script>
